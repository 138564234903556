<template>
    <b-card>
        <b-row>
            <b-col>
                <div class="head-btn">
                    <b-button
                        :to="{ name: 'general-documents'}"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <OwnerDocsForm :documentData="data" :isSavingGeneralDocument="isSavingGeneralDocument" @save-document="save" @delete-membership="deleteMembership"/>
    </b-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { showAlertMessage, stringAleatorio } from '@/helpers/helpers';
import OwnerDocsForm from '@/modules/fivesClub/components/catalogs/ownersWebsite/OwnerDocsForm'
import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    components: {
        OwnerDocsForm
    },
    data() {
        return {
            data:{
                name: null,
                type: 4,
                description: null,
                status: true,
                resort: null,
                file: null,
                memberships: []
            },
            isSavingGeneralDocument: false,
            isLoadingData: false
        };
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['saveOwnersGeneralDocument']),

        async save(data) {
            this.isSavingGeneralDocument = true;
            data.createdBy = this.user.idUser

            const response = await this.saveOwnersGeneralDocument(data);  // saving in backend
            if (response){
                showAlertMessage( 'Ok: proceso finalizado', 'InfoIcon', 'Se ha creado el registro.', 'success', 4000, 'bottom-right')
                this.$router.push({ name: 'general-documents' })
            }

            this.isSavingGeneralDocument = false

        },
        deleteMembership(option){}
    },
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
}
</style>
